import React from 'react';
import { Container } from 'react-bootstrap';

const Subscribe = () => {
    return (
        <Container>
            <iframe src="https://embeds.beehiiv.com/c0fa1ba1-a2dc-4b98-a4d6-fbb538b35da1" data-test-id="beehiiv-embed" width="100%" height="320" frameborder="0" title="subscribe"></iframe>
        </Container>
    );
};

export default Subscribe;