import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <Container>
            <Row className="my-5">
                <Col>
                    <h1 className="text-center">Privacy Policy for Light The Track</h1>
                    <p className="text-center text-muted mb-3">
                        Effective Date: December 8, 2024
                    </p>

                    <p>
                        <strong>Light The Track</strong> ("we," "our," or "us") is committed to protecting your privacy.
                        This
                        Privacy Policy explains how we collect, use, and protect your personal information when you visit
                        <strong> LightTheTrack.org</strong> (the "Site").
                    </p>
                    <p>
                        By using the Site, you consent to the practices described in this Privacy Policy. If you do not
                        agree with
                        this policy, please do not use the Site.
                    </p>

                    <h5>1. Information We Collect</h5>
                    <p>
                        We collect two types of information from visitors to our Site: <strong>personal information</strong>
                        and{' '}
                        <strong>non-personal information</strong>.
                    </p>

                    <h6>Personal Information</h6>
                    <p>
                        Personal information is data that can be used to identify you as an individual. We collect personal
                        information when you:
                    </p>
                    <ul>
                        <li>Sign the petition</li>
                        <li>Make a donation or purchase</li>
                        <li>Subscribe to our newsletter</li>
                        <li>Contact us for more information</li>
                    </ul>
                    <p>
                        Personal information may include, but is not limited to, your name, email address, phone number,
                        mailing
                        address, and payment details.
                    </p>

                    <h6>Non-Personal Information</h6>
                    <p>
                        Non-personal information is data that does not identify you personally. This may include, but is not
                        limited to:
                    </p>
                    <ul>
                        <li>Your IP address</li>
                        <li>Your browser type and version</li>
                        <li>Your device information</li>
                        <li>Your browsing behavior (e.g., pages visited, links clicked)</li>
                    </ul>

                    <h5>2. How We Use Your Information</h5>
                    <p>We use the information we collect for various purposes, including:</p>
                    <ul>
                        <li>To communicate with you about the petition, donations, events, or updates.</li>
                        <li>To process your donations and purchases.</li>
                        <li>To improve the functionality and content of the Site.</li>
                        <li>To respond to your inquiries or feedback.</li>
                        <li>To send you newsletters or other informational emails if you’ve subscribed.</li>
                    </ul>
                    <p>
                        We <strong>agree not to sell your personal information</strong> to third parties. However, we may
                        share your
                        information with trusted service providers who assist us with operating the Site and processing
                        transactions
                        (e.g., payment processors, email marketing services). These providers are required to protect your
                        data in
                        accordance with this Privacy Policy.
                    </p>

                    <h5>3. Cookies and Tracking Technologies</h5>
                    <p>
                        We may use cookies, web beacons, and similar technologies to enhance your experience on the Site.
                        These
                        technologies help us track Site usage, improve the Site’s performance, and personalize your
                        experience. Cookies
                        are small text files stored on your device that allow us to remember your preferences and actions.
                    </p>
                    <p>
                        You can control the use of cookies through your browser settings. However, disabling cookies may
                        impact your
                        ability to use certain features of the Site.
                    </p>

                    <h5>4. Data Security</h5>
                    <p>
                        We take reasonable precautions to protect your personal information from unauthorized access, use,
                        or disclosure.
                        This includes using encryption and secure servers for transactions and data storage. However, no
                        method of
                        transmission over the internet or electronic storage is 100% secure, and we cannot guarantee
                        absolute security.
                    </p>

                    <h5>5. Third-Party Links</h5>
                    <p>
                        The Site may contain links to third-party websites that are not operated or controlled by
                        <strong>Light The
                            Track</strong>. We are not responsible for the privacy practices or content of these external
                        sites. We encourage
                        you to review the privacy policies of those third-party websites before providing any personal
                        information.
                    </p>

                    <h5>6. Your Rights and Choices</h5>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access and update your personal information.</li>
                        <li>Opt-out of receiving promotional emails by following the unsubscribe instructions in each email.
                        </li>
                        <li>Request that we delete your personal information, subject to certain legal exceptions.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us at the information provided below.</p>

                    <h5>7. Children's Privacy</h5>
                    <p>
                        The Site is not intended for children under the age of 13, and we do not knowingly collect personal
                        information
                        from children. If you are a parent or guardian and believe we have collected personal information
                        from a child,
                        please contact us immediately so we can take appropriate action to remove the data.
                    </p>

                    <h5>8. Changes to This Privacy Policy</h5>
                    <p>
                        We may update this Privacy Policy from time to time. When we do, we will post the updated policy on
                        this page and
                        update the “Effective Date” at the top of the page. We encourage you to review this Privacy Policy
                        periodically to
                        stay informed about how we are protecting your information.
                    </p>

                    <h5>9. Contact Us</h5>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please
                        contact us at:
                    </p>
                    <p>
                        <strong>Light The Track</strong>
                        <br />
                        Email: <a href="mailto:contact@lightthetrack.org">info@lightthetrack.org</a>
                        <br />
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicy;