import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';


function Petition() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        optIn: false,
        zipcode: '',
        comments: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const response = await fetch('https://worker.lightthetrack.org', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, timestamp: new Date() }),
            });

            if (response.ok) {
                setSubmitted(true);
                setSubmitError(false);
                setFormData({
                    name: '',
                    email: '',
                    optIn: false,
                    zipcode: '',
                    comments: '',
                });
            } else {
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitError(true)
        }
    }

    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <p>
                        Help us show the City of New York and elected officials that the East River Park Track is important to our community and that the addition of sports lighting is crucial to its future.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Button className='m-2' variant="info" size='lg' href="#addsignature">🖊️ Add your signature</Button>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <h1>Petition to Light the East River Park Track</h1>
                    <p>We, the undersigned, call on the City of New York to amend the East Side Coastal Resiliency (ESCR) project to include <strong>sports lighting</strong> for the East River Park Track.</p>
                    <p>Additionally, we call upon our city, state and federal elected officials to assist in securing funding for this critical addition to the project.</p>

                    <p><strong>The John V. Lindsay East River Park Track is a vital resource</strong> for the Lower Manhattan community, serving thousands of runners, school groups, and local residents. However, the track currently lacks sufficient lighting, making it unsafe after dark. The need for lighting has been long overdue.</p>

                    <p>As part of the East Side Coastal Resiliency (ESCR) project, <strong>the track is set to be reconstructed, but the new plans do not include sports lighting</strong>. This is a once-in-a-generation opportunity to add sports lighting to a fully funded project, ensuring the track is safe, accessible, and usable year-round.</p>

                    <p>We urge the city to incorporate sports lighting into the track design for the following reasons:</p>

                    <p>Safety: The current lack of lighting makes the track unsafe after dark, increasing the risk of personal harm and athletic injuries. Stadium lighting is crucial to providing a safe environment for all users and serves as a deterrent to crime and vandalism.</p>

                    <p>Access: Many New Yorkers rely on evening hours to exercise. The addition of lighting will ensure that the track can be used by the community in the evenings, year-round.</p>

                    <p>Maximizing Investment: Adding lighting now, as part of the ongoing reconstruction, is far more cost-effective than retrofitting the track at a later date. This investment will ensure the track serves its full potential as a safe, accessible community space.</p>

                    <p>The East River Park Track is the only regulation track in Manhattan south of 135th Street and is a vital asset to the community. We cannot afford to miss this opportunity to ensure it meets the needs of all New Yorkers.</p>
                </Col>
            </Row>
            <Row className="mb-5 bg-light py-5" id="addsignature">
                <Col>
                    <h1>Add your signature</h1>
                    <p>Sign the petition to show your support for lighting the East River Park Track.</p>
                    {!submitted &&
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className="mt-3">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formOptIn" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    name="optIn"
                                    label="I would like to receive updates from Light the Track"
                                    checked={formData.optIn}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formZipcode" className="mt-3">
                                <Form.Label>Zipcode*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zipcode"
                                    value={formData.zipcode}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formComments" className="mt-3">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="comments"
                                    value={formData.comments}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            {submitError &&
                                <Alert variant="danger" className="mt-3">An error occurred while submitting the form. Please try again.</Alert>
                            }
                            <Button variant="primary" type="submit" className="mt-3">
                                Submit
                            </Button>
                        </Form>
                    }
                    {submitted &&
                        <Alert variant="success" className="mt-3">Thank you for signing the petition! Your signature has been recorded.</Alert>
                    }
                </Col>
            </Row>

        </Container>
    );
}


export default Petition;