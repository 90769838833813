import React from 'react';
import { Container } from 'react-bootstrap';

const Subscribe = () => {
    return (
        <Container className='mt-4'>
            <p>Thanks to the following sources for providing resources and inspiration for this site:</p>
            <p><a className='text-secondary text-decoration-none' href="https://www.flaticon.com/free-icons/stadium" title="stadium icons">Stadium icons created by Sikeystd - Flaticon</a></p>
        </Container>
    );
};

export default Subscribe;